import React from 'react';

import { motion, px } from 'framer-motion';
import { useState, useEffect } from 'react';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import { TextField } from '@mui/material';
import { FaHandshake, FaRegHandshake } from 'react-icons/fa';
import { client } from '../../client';
import firebase from '../../API/Firebase'; // Import the Firebase instance


const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },  
  },
};
const Header = () => {

  const [data, setData] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);



const openCV = () => {
  window.open('http://dandamiano.dantechlab.com/daniel_damiano/Daniel%20Elliot%20Damiano%20CV.pdf');
};

const [quotation, setQuotation] = useState({ email: ' ', description: '', amount: '', status: 'pending' });
const [isValid, setIsValid] = useState(true);

const onSubmiteQuot = async (e) => {
  e.preventDefault();
  console.log(quotation)
  setLoading(true); // Set loading state to true
  // Perform action only if the input value matches email or phone number format
  if (validateInput(quotation.email)) {
    try {
      // Simulate a loading delay of 2 seconds using setTimeout
      setTimeout(async () => {
        const contactsCollection = firebase.firestore().collection('quotations');
        await contactsCollection.add(quotation);

      console.log(quotation)
        setLoading(false);
        setIsFormSubmitted(true) // Set loading state to false after data submission
      }, 2000); // 2-second delay
    } catch (error) {
      console.log(error)
      setLoading(false); // Set loading state to false in case of an error
      alert("Something Wrong on our end")
    }
   
    console.log('Valid input:', quotation);
  } else {
    setLoading(false)
    // Input is not valid, handle the error or provide feedback to the user
    setIsValid(false)
    console.log('Invalid input');
  }
};

const handleInputChange = (event) => {
  const {name, value } = event.target;
  setQuotation({ ...quotation, [name] : value });
  setIsValid(true); // Reset validation status on input change
};

const validateInput = (input) => {
  // Regular expressions to check for email or phone number format
  const emailRegex = /\S+@\S+\.\S+/;
  const phoneRegex = /^\d+$/; // Change this regex to match your phone number format

  return emailRegex.test(input) || phoneRegex.test(input);
};


return (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="home__deteils">
        <div className="app__header-badge">
        <div className="desktop-badge badge-cmp app__flex">
          <span>👋</span>
          <div style={{ marginLeft: 20 }}>
            <p className="p-text">Hello, I am</p>
            <h1 className="head-text">Daniel</h1>
          </div>
        </div>

        <div className="mobile-badge badge-cmp app__flex">
          <span>👋</span>
          <div style={{ marginLeft: 20 }}>
            <p className="p-text">Hello, I am Daniel</p>
            <p className="bold-text badge-text">Software Engineer</p>
            <p className="p-text badge-text">Freelancer</p>
          </div>
        </div>

        <div className="desktop-badge tag-cmp app__flex">
          <p className="bold-text">Software Engineer</p>
          <p className="p-text">Freelancer</p>
        </div>
        </div>
        <div className="download_cv">
          <Button 
            variant="outlined" 
            startIcon={<FileDownloadSharpIcon/>}
            sx={{
              width: 250,
              border: 3
            }}
            onClick={openCV}
            >
            DOWNLOAD MY CV
          </Button>
        </div>
      </div>
      
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.profile} alt="profile_bg" />
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      />
    </motion.div>

    {/* <motion.div
      className="home_bottom_bar"
    >
       <div className="bottom_tag-cmp app__flex">
          <p className="bold-text">Software Engineer</p>
          <p className="p-text">Freelancer</p>
      </div>
    </motion.div> */}
   <motion.div
      className='home_bottom_bar'
   >
     <div className="bottom_cv">
          <Button 
            variant="outlined" 
            startIcon={<FileDownloadSharpIcon/>}
            sx={{
              width: 250,
              border: 3
            }}
            className='bottom_cv-button'
            onClick={openCV}
            >
            DOWNLOAD MY CV
          </Button>
      </div>

      <div className="get_quotation">
        {!isFormSubmitted ? (
        <>
          <TextField
          className='get_quotation-input'
          name='email'
          value={quotation.email}
          onChange={handleInputChange}
          placeholder="Enter your email or phone number"
          error={!isValid} // Show error state based on validation
          helperText={!isValid ? 'Enter correct emil or phone number' : ''}
        >

        </TextField>
        <Button 
              variant="outlined" 
              startIcon={loading ? <CircularProgress /> : <FaRegHandshake/>}
              sx={{
                // width: 100,
                border: 3
              }}
              className='get_quotation-button'
              onClick={onSubmiteQuot}
              >
              {loading ? 'Loading...' : 'Get Quotation'}
        </Button>
      </>
        ): (
          <h4>
          Got it! I've received your  contact. I'll get back to you shortly with the details. Thank you!
          </h4>
        )}
      </div>
   </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.kotlin, images.node, images.csharp].map((circle, index) => (
        <div className="circle-cmp app__flex" key={`circle-${index}`}>
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>

  </div>
);
};

export default AppWrap(Header, 'home');


