// Firebase.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'; // Import only the Firestore module
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD5RfU0vnQIZQ6A75FuDsV0pNhrDd-EF-8",
    authDomain: "my-portfolio-e6a16.firebaseapp.com",
    projectId: "my-portfolio-e6a16",
    storageBucket: "my-portfolio-e6a16.appspot.com",
    messagingSenderId: "335459512433",
    appId: "1:335459512433:web:af2a54fd2c21b8381d6bd0",
    measurementId: "G-L5R94MHB4C"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
