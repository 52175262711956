import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
      <h3 className="head-tet">DANIEL DAMIANO</h3>

        {/* <img src={images.logo} alt="logo" /> */}
      </div>

      <ul className="app__navbar-links">
        {['home', 'my services', 'my work', 'skills', 'contact'].map((item) => (
          <li className="app__flex" key={`link-${item}`}>
            {/* <div /> */}
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'services', 'work', 'skills', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>

      <div className='contact_icons'>
        <a href='https://www.facebook.com/daniel.damiano.35/' target='_blank'><img src={images.facebook} alt="logo" /></a>
        < a href='https://wa.me/+265885802476?text=Hello%20there!' target='_blank'><img src={images.whatsapp} alt="logo" /></a>
        <a href='https://www.linkedin.com/in/dandamiano' target='_blank'><img src={images.twitter} alt="logo" /></a>
      </div>
    </nav>
  );
};

export default Navbar;
