// import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

import { createClient } from '@sanity/client';

export const client = createClient({

// export const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,

  // projectId: '82hzp5pc',
  dataset: 'production',
  apiVersion: '2022-01-01',
  useCdn: true,
  // token: "skEwDL2Py3YyMjhKfDGtF9chFU4gs9gsIKjb8JigJqwHTE6c9GYWCr1mLhAI4XMdWZ4BMHDl49fDiDVQXNUxGC4I6G8B2gflALYTLy2MXezCTyHYX3rKGpdcb5XZ2tMVPjLXaWgge0lqTk0kZ8TWfflg5Sm62DYf75gGoNojkahbbf15yzBg"
  token: process.env.REACT_APP_SANITY_TOKEN,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
 